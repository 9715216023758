import { css } from '@emotion/react';
import React from 'react';

export default function Body({ children }) {
  return (
    <section css={wrapper}>
      <section css={contentWrapper}>
        {children}
      </section>
    </section>
  )
}

const wrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
`

const contentWrapper = css`
  width: 1400px;
  background: #fff;
  
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`