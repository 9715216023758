import { css } from '@emotion/react';
import React from 'react';

export default function SectionTitle({ title }) {
  return (
    <section css={wrapper}>
      <img css={titleImage} src={`/title-${title}.png`} />
    </section>
  )
}

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  > span {
    font-size: 50px;
    font-weight: 800;
    color: #555555;
    font-family: "Exo2";
  }

  @media screen and (max-width: 450px) {
    > span {
      font-size: 36px;
    }
  }
`

const titleImage = css`
  max-width: 231px;
  height: auto;
  @media screen and (max-width: 450px) {
    width: 160px;
  }
`