import { css } from '@emotion/react';
import React from 'react';
import { games } from '../datas';

export default function Games() {
  return (
    <section css={wrapper}>
      <div css={line} />
      {games.map(({ title, img, google, apple, isPublished }) => (
        <section css={cardWrapper}>
          
          <img css={headerImg} src={img} alt={title} />
          
          <div className='cardBody' css={cardBody}>
            <section css={bodyTitleWrapper}>
              <div css={cardTitle}>{title}</div>
            </section>
            <section css={cardFooter}>
              { 
                isPublished  ? (
                  <>
                    <a target="_blank" href={apple} css={buttonWrapper}>
                      <img css={iconImg} src="/icon-apple.png" alt="apple-link" />
                    </a>
                  <a target="_blank" href={google} css={buttonWrapper}>
                    <img css={iconImg} src="/icon-google.png" alt="google-link" />
                  </a>
                  </>
                ) : (
                  <div css={comingSoonText}>Coming Soon</div>
                )
              }
        
            </section>
          </div>
          
        </section>
      ))}
    </section>
  )
}

const line = css`
  width: 100%;
  height: 4px;
  background-color: #e40606;
  margin-bottom: 6px;
`

const wrapper = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10%;
  
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 6%;
    box-sizing: border-box;
  }
`

const cardWrapper = css`
  width: 33%;
  margin-bottom: 42px;
  line-height: 0;
  border: solid 1px rgba(34, 24, 21, 0.3);
  position: relative;
  .cardBody {
    visibility: hidden;
  }

  &:hover {
    .cardBody {
      visibility: visible;
    }
  }

  &:active {
    .cardBody {
      visibility: visible;
    }
  }

  @media screen and (max-width: 768px) {
    width: 49%;
    box-sizing: border-box;
    margin-bottom: 6px;
  }
`

const headerImg = css`
  width: 100%;
  height: auto;
  line-height: 0;
`

const cardBody = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e40606;
  z-index: 10;
  
`

const bodyTitleWrapper = css`
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
`

const cardTitle = css`
  font-family: "NotoSans";
  font-weight: bold;
  color: #fff;
  line-height: 1;
  font-size: 40px;
  margin-bottom: 2%;
  text-align: center;
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`

const comingSoonText = css`
    font-family: "NotoSans";
    font-weight: bold;
    color: #fff;
    line-height: 1;
    font-size: 30px;
    margin-bottom: 2%;
    text-align: center;
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
    @media screen and (max-width: 450px) {
      font-size: 16px;
    }
`

const cardFooter = css`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 21%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0 7%;
  box-sizing: border-box;
`

const buttonWrapper = css`
  width: 48%;
  height: 0;
  padding-bottom: 21%;
  position: relative;
  text-align: center;
  cursor: pointer;
`

const iconImg = css`
  width: 35%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`