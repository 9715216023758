import { useCallback, useEffect, useMemo, useRef, useState } from "react";

class WindowResizeEventSingleTon {
  constructor() {
    this.size = window?.innerWidth;
    this.listeners = [];
  }

  callbackFunction() {
    this.listeners.map(listener => listener(window.innerWidth));
    this.size = window.innerWidth;
  }

  registEvent() {
    window.addEventListener("resize", this.callbackFunction.bind(this));
  }

  deleteEvent() {
    window.removeEventListener("resize", this.callbackFunction.bind(this));
  }

  addListeners(listener) {
    this.listeners.push(listener);
  }  

  removeListener(listener) {
    this.listeners = this.listeners.filter(prev => prev !== listener);
  }

  hasListener(listener) {
    return this.listeners.findIndex(prev => prev === listener) !== -1;
  }
}

let resizeSingleTon;

if (typeof window !== "undefined") {
  resizeSingleTon = new WindowResizeEventSingleTon();
}

/**
 * 
 * @param {Record<string, number>} param 
 * @returns 
 */
export default function useWindowSize(param) {
  const prevEventListener = useRef();
  const paramKeys = useMemo(() => Object.keys(param).sort((a, b) => param[a] - param[b]), [param]);
  
  const defaultCurrent = useMemo(() => {
    if (typeof window === "undefined") return paramKeys[paramKeys.length - 1];
    for (let i = 0; i < paramKeys.length; i++) {
      if (param[paramKeys[i]] >= window?.innerWidth) return paramKeys[i];
    }
    return paramKeys[paramKeys.length - 1];
  }, [param, paramKeys]);

  const [current, setCurrent] = useState(defaultCurrent);

  const forceRender = useCallback((size) => {
    for (let i = 0; i < paramKeys.length; i++) {
      if (param[paramKeys[i]] >= size) return setCurrent(paramKeys[i]);
    }
    return setCurrent(paramKeys[paramKeys.length - 1]);
  }, [paramKeys, param]);

  useEffect(() => {
    if (prevEventListener.current && resizeSingleTon.hasListener(prevEventListener.current)) {
      resizeSingleTon.removeListener(prevEventListener.current);
    }

    if (resizeSingleTon.listeners.length === 0) {
      resizeSingleTon.registEvent();
      resizeSingleTon.addListeners(forceRender)
    } else {
      resizeSingleTon.addListeners(forceRender)
    }
    
    prevEventListener.current = forceRender;
    
    return () => {
      resizeSingleTon.removeListener(forceRender);
      if (resizeSingleTon.listeners.length === 0) {
        resizeSingleTon.deleteEvent();
      }
    }
  }, [forceRender]);

  return {
    current
  };
}