import { css } from '@emotion/react';
import React from 'react';
import useWindowSize from '../hooks/useWindowSize';

export default function GateImage() {
  const { current } = useWindowSize({
    'tablet': 768,
    'pc': 769
  });

  if (typeof window === "undefined") return null;

  return (
    <header css={wrapper}>
      {current === "tablet" && <img src="/img-main-mobile.jpg" css={gateImageMobile} alt="gate" importance="high" />}
      {current === "pc" && (
        <>
          <section css={videoWrapper}>
            <video css={gateMainVideo} src="/supermagic-main.mp4" autoPlay={true} muted loop></video>
          </section>
          <img src="/img-book.png" css={gateBookImage} alt="book" importance="high" />
        </>
      )}
    </header>
  )
}

const gateImageMobile = css`  
  display: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    display: block;
  }
`

const wrapper = css`
  position: relative;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  background: #1A101B;
  
  > img {
    line-height: 0;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
`

const videoWrapper = css`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const gateMainVideo = css`
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  
`

const gateBookImage = css`
  width: 700px;
  height: auto;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -31%);
  z-index: 1;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`