import { css } from '@emotion/react';
import React, { useEffect, useRef } from 'react';


const companyLat = [37.49999475037807, 127.03684894788732]

export default function CompanyNaverMap() {
  const mapRef = useRef();
  useEffect(() => {
    if (!window.naver.maps) return;
    const map = new window.naver.maps.Map(mapRef.current, {
      center: new window.naver.maps.LatLng(companyLat[0], companyLat[1]),
      zoom: 17
    })
    new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(companyLat[0], companyLat[1]),
      map,
    })
  }, []);
  
  return (
    <section css={wrapper}>
      <div css={line} />
      <div css={scrollDefence} />
      <div ref={mapRef} css={mapContents}></div>
    </section>
  )
}

const line = css`
  width: 100%;
  height: 4px;
  background-color: #e40606;
  margin-bottom: 6px;
`

const wrapper = css`
  position: relative;
  overflow: hidden;
`

const mapContents = css`
  width: 100%;
  height: 0;
  padding-bottom: 42%;
  border: 1px solid rgba(34,24,21,0.3);
  box-sizing: border-box;
  background-color: #f2f2f2;
  @media screen and (max-width: 450px) {
    padding-bottom: 68%;
  }
`

const scrollDefence = css`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
`