import { css } from '@emotion/react'
import React from 'react'

export default function NavBar({ onMenuClick }) {
  return (
    <nav css={wrapper}>
      <div tabIndex={0} role="button" css={cursor} onClick={() => onMenuClick && onMenuClick("Games")}>GAMES</div>
      <div css={redDot}></div>
      <div tabIndex={-1} role="button" css={cursor} onClick={() => onMenuClick && onMenuClick("ContactUs")}>CONTACT US</div>
      <div css={redDot}></div>
      <div css={cursor}>
        <a href="https://recruit.111percent.net/" target="_blank">
          RECRUIT
        </a>
      </div>
    </nav>
  )
}

const wrapper = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: #ffffff;
  padding: 15px 0;
  font-family: "Exo2";
  background: rgba(0,0,0,0.3);
  div + div {
    margin-left: 1.5%;
  }

  @media screen and (max-width: 450px) {
    font-size: 16px;
    div + div {
      margin-left: 0;
    }

    padding: 15px 9% 8px;
    box-sizing: border-box;
    justify-content: space-around;
  }
`

const redDot = css`
  width: 6px;
  height: 6px;
  background: #cccccc;
  opacity: 0.5;
  transform: rotate(45deg);

  @media screen and (max-width: 450px) {
    width: 5px;
    height: 5px;
  }
`

const cursor = css`
  cursor: pointer;
  &:hover {
    color: #e40606;
    a {
      color: #e40606;
    }
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }
`