import { css } from '@emotion/react';
import React from 'react';

export default function ToTopButton() {
  return (
    <section css={wrapper}>
      <img onClick={() => window.scrollTo(0, 0)} src="/btn-top.jpg" alt="toTopButton" importance="low" />
    </section>
  )
}

const wrapper = css`
  img {
    cursor: pointer;
  }

  @media screen and (max-width: 450px) {
    img {
      width: 70%;
      height: auto;
    }
  }
`
